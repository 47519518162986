import { KitStatuses } from "@/components/constants.js"
import { CountryId, PowerType } from "@evercam/shared/types"
import { toTitleCase } from "@evercam/shared/utils"

function getCountryNames() {
  return Object.keys(CountryId)
    .filter(isNaN)
    .map((key) => ({ name: toTitleCase(key), value: CountryId[key] }))
    .sort((a, b) => a.name.localeCompare(b.name))
}

export default {
  name: {
    component: "TextFieldSearchFilter",
  },
  serial: {
    component: "TextFieldSearchFilter",
  },
  cameras: {
    component: "TextFieldSearchFilter",
  },
  status: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ value: "", name: "All" }, ...KitStatuses],
      "menu-props": { offsetY: true },
      isAutocomplete: true,
      value: "",
    },
  },
  countryId: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ value: "", name: "All" }, ...getCountryNames()],
      "menu-props": { offsetY: true },
      isAutocomplete: true,
      value: "",
    },
  },
  powerType: {
    component: "SelectSearchFilter",
    attributes: {
      items: Object.entries(PowerType).map(([name, value]) => ({
        name,
        value,
      })),
      multiple: true,
      "menu-props": { offsetY: true },
      isAutocomplete: true,
    },
  },
}
